@tailwind base;
@tailwind components;

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-size: 14px;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  @apply bg-slate-900;
}

*::-webkit-scrollbar-thumb {
  @apply bg-slate-200 border-2 rounded-md;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.my-whatsapp-gradient {
  @apply bg-gradient-to-t from-[#5BD166] to-[#27B63E];
}

.my-bg-gradient-1 {
  @apply bg-gradient-to-br from-[#396afc] to-[#2948ff];
}

.my-smooth-transition-1 {
  @apply transition duration-300 ease-in-out;
}

.my-bg-hover-effect-1 {
  @apply my-smooth-transition-1 hover:bg-wabmGreen;
}

.my-hover-effect {
  @apply my-smooth-transition-1 hover:text-wabmGreen;
}

.my-hover-effect-2 {
  @apply inline-block relative text-white after:content-[''] after:absolute after:w-full after:scale-x-0 after:h-[1px] after:bottom-0 after:left-0 after:bg-slate-100 after:origin-bottom-right after:transition after:ease-out after:duration-300 hover:after:scale-x-100 hover:after:origin-bottom-left;
}

.my-page-button-layout {
  @apply my-smooth-transition-1 px-10 py-3 text-white font-medium rounded-md cursor-pointer;
}

.wbm-cta-btn-1 {
  @apply my-smooth-transition-1 bg-wabmBlue p-3 text-white font-medium uppercase rounded-md hover:drop-shadow-lg;
}

.wbm-cta-btn-2 {
  @apply my-smooth-transition-1 bg-wabmBlue hover:bg-wabmGreen px-2 py-1 text-white text-sm rounded-md hover:drop-shadow-lg;
}

.my-button-style-1 {
  @apply bg-wabmAiBlue px-3 py-2 text-white rounded-md my-hover-effect disabled:bg-slate-500 disabled:hover:text-white cursor-pointer;
}

.my-button-style-1-selected {
  @apply bg-wabmGreen px-3 py-2 text-white hover:text-white rounded-md;
}

.my-button-style-2 {
  @apply md:bg-slate-900 hover:bg-black md:px-7 md:py-3 hover:text-wabmGreen rounded-md my-hover-effect cursor-pointer;
}

.my-button-style-2-selected {
  @apply md:bg-black md:px-7 md:py-3 text-wabmBlue rounded-md cursor-pointer;
}

.my-button-style-3 {
  @apply hover:bg-wabmBlue px-10 py-3 text-white hover:text-white rounded-md my-hover-effect cursor-pointer border-2 border-white;
}

.my-button-style-4 {
  @apply my-smooth-transition-1 bg-white p-1 text-wabmBlue hover:text-wabmGreen rounded-md;
}

.my-button-style-5 {
  @apply my-smooth-transition-1 bg-wabmBlue hover:bg-wabmGreen px-3 py-1 text-white rounded-md cursor-pointer;
}

.my-secondary-button {
  @apply my-smooth-transition-1 bg-slate-50 hover:bg-slate-200 py-2 px-3 inline-flex items-center gap-2 text-slate-500 border-2 border-slate-400 rounded-lg;
}

.my-button-style-cancel {
  @apply my-smooth-transition-1 bg-red-500 text-white p-2 hover:bg-red-600 hover:text-white rounded-md;
}

.my-input-style-1 {
  @apply h-8 px-4 rounded-sm outline-0;
}

.wbm-card-1 {
  @apply bg-slate-200 bg-opacity-60 p-5 rounded-md drop-shadow-md;
}

.wbm-card-2 {
  @apply p-3 border-slate-300 border-2 rounded-md;
}

.wbm-card-3 {
  @apply px-2 py-3 border-slate-300 border-y-2;
}

.wbm-select-1 {
  @apply bg-white h-10 p-1 rounded-md drop-shadow-sm;
}

.my-card-style-1 {
  @apply bg-slate-200 w-full p-4 rounded-md break-words;
}

.my-card-style-2 {
  @apply bg-wabmBlue bg-opacity-75 w-full p-5 rounded-md break-words;
}

.my-card-style-3 {
  @apply bg-slate-200 w-full px-3 py-2 rounded-md;
}

.my-popup-style-1 {
  @apply bg-slate-200 bg-opacity-95 w-56 p-3 flex flex-col justify-center items-center text-center text-black shadow-xl rounded-md gap-3;
}

.my-horizontal-line {
  @apply bg-slate-300 bg-opacity-80 w-full mx-auto my-5 h-[1px];
}

.wabm-font-style-1 {
  @apply w-fit mx-auto px-2 font-Montserrat text-3xl text-wabmGreen text-center;
}

.wabm-font-style-2 {
  @apply font-Montserrat text-2xl;
}

.dropzone-box {
  @apply bg-slate-100 hover:bg-slate-200 p-5 flex flex-col justify-center items-center rounded-lg border-2 border-slate-400 border-dashed cursor-pointer;
}

.my-data-row-1 {
  @apply my-smooth-transition-1 bg-slate-200 hover:bg-wabmBlue/30 w-full p-3 flex flex-wrap md:flex-nowrap justify-between items-center gap-5 rounded-md cursor-pointer;
}

.my-input-box-1 {
  @apply w-full border-slate-800 placeholder:text-slate-800;
}

.my-message-sent {
  @apply bg-green-200 bg-opacity-90 w-fit max-w-lg px-2 py-1 break-words rounded-t-md rounded-bl-md drop-shadow-sm;
}

.my-message-received {
  @apply bg-white bg-opacity-90 w-fit max-w-lg px-2 py-1 break-words rounded-tr-md rounded-b-md drop-shadow-sm;
}

.my-full-screen-window-style-1 {
  @apply bg-wabmAiBlue bg-opacity-70 backdrop-blur-md w-full h-full fixed top-0 left-0 z-10;
}

.my-full-screen-window-style-2 {
  width: 37%;
  height: 29%;
  margin-top: 15%;
  margin-left: 50%;
}

.my-toggle-switch {
  @apply mt-[0.3rem] mr-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-red-500 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-slate-50 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-wabmGreen checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-slate-50 checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-slate-900 checked:focus:bg-wabmGreen checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s];
}

.my-context-menu {
  @apply my-smooth-transition-1 bg-white px-1 py-2 absolute space-y-1 rounded-md rounded-tl-none z-40 cursor-pointer;
}

*::-webkit-calendar-picker-indicator {
  @apply bg-white p-2 cursor-pointer rounded-md border-0 outline-none;
}

/* Floating animation effect */

@keyframes float {
  0%,
  100% {
    transform: translatey(-5px);
  }
  50% {
    transform: translatey(5px);
  }
}

.my-floating-animation {
  animation: float 3s ease-in-out infinite;
}

/* ********************** */

/* Waves Animation */

.my-wave-effect,
.my-wave-effect::before,
.my-wave-effect::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250vw;
  height: 250vw;
  margin-left: -125vw;
  transform-origin: 50% 50%;
  background-color: transparent;
  border-radius: 38% 42%;
  box-shadow: inset 0 0 10vw darkviolet;
  animation: spin 36s infinite linear;
}

.my-wave-effect::before {
  width: 105%;
  height: 95%;
  margin-top: -125vw;
  transform-origin: 49% 51%;
  border-radius: 40% 38%;
  box-shadow: inset 0 0 10vw #25d366;
  animation: spin 25s infinite linear;
}

.my-wave-effect::after {
  width: 102%;
  height: 98%;
  margin-top: -125vw;
  transform-origin: 51% 49%;
  border-radius: 48% 42%;
  box-shadow: inset 0 0 10vw #3390ff;
  animation: spin 10s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* ********************** */

/* Typewriter animation effect */

.my-writer-text {
  display: inline-block;
  overflow: hidden;
  padding-right: 5px;
  animation: typing 2s steps(30, end), blink 0.75s step-end infinite;
  white-space: nowrap;
  font-size: 30px;
  font-weight: 700;
  border-right: 3px solid #25d366;
  box-sizing: border-box;
}

@keyframes typing {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #25d366;
  }
}

/* ********************** */

/* 404 Page Train */

.st0 {
  fill: #fff;
}
.st2 {
  fill: #606c88;
}
.st3 {
  fill: #3390ff;
}
.st4,
.st6 {
  fill: #fff;
  stroke: #65c7f7;
  stroke-miterlimit: 10;
}
.st6 {
  stroke: #606c88;
  stroke-width: 2;
}
.st7,
.st8,
.st9 {
  stroke-miterlimit: 10;
  @apply stroke-wabmBlue;
}

.st7 {
  stroke-width: 5;
  stroke-linecap: round;
  fill: none;
}
.st8,
.st9 {
  fill: #fff;
}
.st9 {
  fill: none;
}

#my-cloud1 {
  animation: cloud003 15s linear infinite;
}

#my-cloud2 {
  animation: cloud002 25s linear infinite;
}

#my-cloud3 {
  animation: cloud003 20s linear infinite;
}

#my-cloud4 {
  animation: float 4s linear infinite;
}

#my-cloud5 {
  animation: float 8s linear infinite;
}

#my-cloud7 {
  animation: float 5s linear infinite;
}

#my-tracks {
  animation: slide 650ms linear infinite;
}

#my-bumps {
  animation: land 10000ms linear infinite;
}

@keyframes jig {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0px);
  }
}

#my-car-layers {
  animation: jig 0.35s linear infinite;
}

@keyframes land {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(1000px);
  }
}

@keyframes slide {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(100px);
  }
}

@keyframes cloud001 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }
  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@keyframes cloud002 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }
  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@keyframes cloud003 {
  0% {
    transform: translateX(-1000px) translateY(3px);
  }
  100% {
    transform: translateX(1000px) translateY(0);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px) translateX(0);
  }
  50% {
    transform: translateY(8px) translateX(5px);
  }
  100% {
    transform: translateY(0px) translateX(0);
  }
}

#my-bracefront,
#my-braceback {
  animation: braces 1s linear infinite;
}

@keyframes braces {
  0% {
    transform: translateX(-2px);
  }
  25% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-2px);
  }
  75% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(-2px);
  }
}

/* ********************** */

/* Glassmorphic Effect */

.my-glassmorphic-effect {
  @apply bg-wabmBlue bg-opacity-30 px-3 py-2 lg:px-5 lg:py-7 rounded-md backdrop-blur-md;
}

/* ********************** */

/* Circle Animation */

.my-circle-1 {
  position: absolute;
  border-radius: 50%;
  animation: collide1 2s infinite;
  @apply w-12 h-12 rounded;
}

.my-circle-2 {
  position: absolute;
  border-radius: 50%;
  animation: collide2 2s infinite;
  @apply w-24 h-24 rounded;
}

@keyframes collide1 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes collide2 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.react-flow .react-flow__handle {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #433664;
}

.react-flow .react-flow__handle-top {
  top: -6px;
}

.react-flow .react-flow__handle-bottom {
  bottom: -5px;
}

.react-flow .react-flow__node {
  height: 40px;
  width: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 2px;
  border-color: #25d366;
  border-width: 1px;
  font-weight: 700;
}

.react-flow__panel a {
  display: none;
}

.react-flow .react-flow__edge path,
.react-flow__connectionline path {
  stroke-width: 2;
}

.wrapper {
  flex-grow: 1;
  height: 82vh;
  width: 100%;
}

.date-picker-button {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}

/* ********************** */

@tailwind utilities;
